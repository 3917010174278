@tailwind base;
@tailwind components;
@tailwind utilities;

.aboutUs{
  background-color: f2f3f8;
}
.Bar{
    
    position: relative;
    margin-inline-start: 10%;
    margin-inline-end: 10%;
    
    border: 5px solid transparent; /* Set the initial border as transparent */
    background-color: rgb(11, 5, 56);
    /* Define the linear gradient border image */
    border-image: linear-gradient(to right, rgb(0, 255, 110), rgb(255, 0, 0));
    border-image-slice: 1;

}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

  }
  

 
  @media screen and (max-width: 767px) {
    .cardM {
     
      margin:50px ;  
      margin-block-start: 20px;
      margin-block-end: 20px;
      /* This sets the height of the container to the full viewport height */
    }
    .Bar{
      margin-top: -250px;
    
    
    
     
  }
  }
  
   

  @media screen and (max-width: 520px) {
    .content {
     
        margin: 0px -120px -0px;

      /* This sets the height of the container to the full viewport height */
    }
 
  
  }

.slide-container{
    
    position: relative;
}

 
 
@media screen and (max-width: 768px) {
  .Bar {
   
      margin-top: -30rem;
      background-color: rgb(11, 5, 56);
      border: 5px solid transparent; /* Set the initial border as transparent */

      /* Define the linear gradient border image */
      border-image: linear-gradient(to right, rgb(0, 255, 110), rgb(255, 0, 0));
      border-image-slice: 1;

    /* This sets the height of the container to the full viewport height */
  }


}
 

 
 
  
.construct{
  position: relative;
   background-attachment: fixed; 
   /* Ensure the container has a relative position */
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  
}
 

 /* Define the initial state of the underline */
.getqty {
  position: relative;
  text-decoration: none;
  color: #333; /* Change this to your desired text color */
}


/* Create the underline element */
.getqty::before {
  content: '';
  position: absolute;
  bottom: 5px; /* Adjust the distance of the underline from the text */
  left: 0;
  width: 0;
  height: 2px; /* Adjust the thickness of the underline */
  background-color: white; /* Change this to your desired underline color */
  transition: width 0.9s ease-in-out; /* Adjust the animation duration and easing as needed */
}

/* Define the hover effect for the underline */
.getqty:hover::before {
  width: 100%;
}

/* Define your initial styles here */

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-in {
  animation: slide-in 1s ease forwards;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Initially translated down */
}
.image-overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Transparent by default */
  transition: background-color 0.8s ease; /* Smooth transition effect */
}
.btn1{
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 200;
  
}

.bg-black:hover .image-overlay  {
  background-color: rgba(0, 0, 0, 0.7);
   /* Semi-transparent black on hover */
}
.bg-black:hover .btn1{
  opacity: 1;
}






.underliners {
  text-decoration: none; /* Remove default underline */
  position: relative;
}

.underliners::after {
  content: ""; /* Create a pseudo-element for the underline */
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  height: 2px; /* Adjust the thickness of the underline */
  background-color: #3498db; /* Set the color of the underline */
  transition: width 0.3s ease-in-out; /* Set the transition properties for the underline */
}
.whyunder1:hover .underliners::after{
  width: 50%;
}
.whyunder2:hover .underliners::after{
  width: 50%;
}
.whyunder3:hover .underliners::after{
  width: 50%;
}
.aboutUSS{
  background-image: url(./img/contact-us-back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
}
.contactus{
  
  background-image: url(./img/contact-us-back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  justify-content: center;
   
}