@keyframes fade-in-letters {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .animate-fade-in-letters {
    animation: fade-in-letters 1s ease-in-out;
  }
  
  .card {
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-inline-start: 35%;
    
  }
  .containers {
 
    align-items:center ;
      
    
      
      
  }
 .bbo{
  align-items: center;
  width: 100%;
  position: relative;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
  
 }
 
 