  .container {
    width: 80%;
    margin: 0px auto;
 } 
  
 .white {
     color: #fff !important;
 }
 
 .d__flex {
    display: flex;
 }
 
 .row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
 }
 
 .col__2{
    max-width: 50%;
    display: flex;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
 }
 .component__space {
    padding: 100px 0;
 }
 
 .pointer {
    cursor: pointer;
 }
 
 
 
 
 
 .p__color {
    color: rgba(0, 0, 0, 0.75);
    font-size: 18px;
    line-height: 30px;
    font-family: Poppins, sans-serif;
    margin-bottom: 15px;
 }
 
 
 
 @media only screen and (max-width: 768px) {
      .col__2 {
       max-width: 100%;
      }
 }
  
 
  .col__3 {
    max-width: 33.333333%;
    display: flex;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }

  h1.heading {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    margin-bottom: 10px;
 }

 p.heading.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
 }

.service__box {
   border-radius: 10px;
   background-color:#FEFFFD;
   padding: 40px;
   transition: .4s ease;
   border: 2px solid green;
   height: 100%;
}

 .service__box:hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, rgb(51, 180, 72), #a9e267);
 }

 .service__box:hover .icon {
    color: #fff;
 }

 .service__box:hover  .p__color {
          color: #fff !important;
 }
.service__box:hover p.service__text{
   color: #fff !important;
}
 .icon {
    font-size: 54px;
    font-weight: 400;
    margin-bottom: 23px;
    display: inline-flex;
    color: #00f90c;
 }

 p.service__text.p__color {
    margin-bottom: 0;
 }

 h1.service__text {
    font-size: 19px;
    font-family: Poppins, sans-serif;
    padding: 10px 0px;
 }

 @media only screen and (max-width: 1024px) {
      .service__box {
        padding: 32px;
      }
 }
 #Services{
    background-image: url(../img/service.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.sbo{
    
    margin-inline-start: 50%;
    width: 70%;
     
    position: relative;
    
   }

   .sbo1{
    
     
    width: 70%;
    
    position: relative;
    
   }

   .btn-primary {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px; /* Makes the button round */
    background-color: blue;
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .round-btn::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, transparent 80%);
    transform: translate(-50%, -50%) scale(0);
    animation: twinkle 1.5s ease-in-out infinite;
  }
  
  @keyframes twinkle {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }

  /* Add this CSS to your style or CSS file */
.glassEffectDialog {
  /* From https://css.glass */
background: rgba(158, 226, 246, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(158, 226, 246, 0.3);
 }
 
 /* Adjust the styles for DialogTitle, DialogContent, and DialogActions as needed */
 .dialog{
   background-color: #66a5cf;
 }
  .dialog__background{
   backdrop-filter: blur(5px);
  }


  /* Add this CSS to your style or CSS file */
@media screen and (max-width: 800px) {
   .col__3 {
     max-width: 100%;
     margin-top: 20px;
   }
   
   .service__box {
     padding: 32px; /* Adjust padding to fit better on smaller screens */
   }
 }
 