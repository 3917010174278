.slider {
    width: 100%;
    height: 100vh;
    position: relative;
    
    
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    
  }
  
  @media screen and (min-width: 600px) {
    .slide img {
      width: 100%;
      height: 100%;
    }
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    position: relative;
   
  }
  
  .current {
    opacity: 1;
    transform: translateX(0);
  }
  
  .content {
    position: absolute;
    margin-top: -130px;
    margin-inline-start: 22rem;
    margin-block-end: 23rem;
    opacity: 0;
    width: 50%;
    color: #fff;
    padding: 3rem;
    
    animation: slide-up 1s ease 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    visibility: hidden;
  }
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 23rem;
    }
    100% {
      visibility: visible;
      top: 17rem;
    }
  }
  
  @media screen and (max-width: 940px) {
    .content {
      width: 80%;
      margin-inline-start: 1rem;
    }
    h2 {
      font-size: 1rem;
      font-weight: 200;
      margin-bottom: 1rem;
    }
  }
  
  .content > * {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .current .content {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease;
  }
  

  

  
  hr {
    height: 2px;
    background: white;
    width: 50%;
  }


  h2 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  .slider__para{
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.3;
   
  }
/* Existing styles for larger screens */

/* ... */

/* Adjusted styles for mobile screens */
 
  
 
@media screen and (max-width: 798px) {
  .content {
    width: 80%;
    margin-inline-start: 3rem;
  }
  h2 {
    font-size: 1rem;
    font-weight: 200;
    
  }
}
 
